import React, { useEffect, useState } from "react";
import { Space } from "antd";
import {
  createSampleDiscoveries,
  fetchMyDiscoveries,
  updateDiscoveryEngagementCounts
} from "../../store/actions/discoveries";
import { useString as s } from "../../components/StringProvider";
import { themeProp } from "../../theme";
import Table from "../../components/Table";
import Link from "../../components/Link";
import {
  hasPermission,
  selectCookie,
  selectDiscoveries,
  selectDiscoveriesCount,
  selectDiscoveriesFetchError,
  selectDiscoveriesPagination,
  selectFetchDiscoveriesLoadingState,
  selectRequestState,
  selectDiscoveriesOnlySamples
} from "../../store/reducers";
import MyDiscoveriesDropdown from "./MyDiscoveriesDropdown";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import styled from "styled-components";
import Heading from "../../components/Heading";
import Page from "../../components/Page";
import MyDiscoveriesHeader from "./MyDiscoveriesHeader";
import {
  AlternateViewportContent,
  FullPageLayout
} from "../../components/Layout";
import DiscoveryOperationsConfirmation from "../../components/DiscoveryOperations/DiscoveryOperationsConfirmation";
import InternalServerError from "../ErrorPage/InternalServerError";
import Highlighter from "../../components/Highlighter";
import ExportDiscoveriesDropdown from "./ExportDiscoveriesDropdown";
import { Text, Tooltip } from "../../components";
import { useFeature } from "../../components/FeaturesProvider";
import Permissions from "../../utils/permissions";
import ExportDiscoveryMonitor from "../../components/Discovery/ExportDiscoveryMonitor";
import MyDiscoveriesConstants from "./MyDiscoveriesConstants";
import NavigationMenu from "../../components/NavigationMenu";
import { Phases } from "../../utils/phases";
import PhaseName from "../../components/PhaseName";
import DiscoveryUnsupportedResolutionPage from "../Discovery/DiscoveryUnsupportedResolutionPage";
import CookieNames from "../../utils/cookie-names";
import AccountMatch from "./AccountMatch";
import actionTypes from "../../store/actionTypes";
import SearchBar from "../../components/SearchBar";
import CreateFirstDiscovery from "./CreateFirstDiscovery";
import NewDiscoveryModal from "../../components/DiscoveryOperations/NewDiscoveryModal";
import useLoadingState from "../../utils/use-loading-state";
import DiscoveryOperations, {
  ShareButton,
  EmailButton,
  EngagementButton
} from "../../components/DiscoveryOperations";
import { formatNumberWithLocale } from "../../utils/formatting";
import { useSetting } from "../../components/SettingsProvider";
import pusherService from "../../services/pusher.service";
import ChannelNames from "../../utils/channel-names";
import httpService from "../../services/http.service";
import PusherEventNames from "../../utils/constants/pusher-event-names";

const MyDiscoveries = ({
  discoveries,
  pagination,
  discoveriesCount,
  onlySamples,
  canSeeAllDiscoveries,
  canExportDiscoveries,
  fetchMyDiscoveries,
  showDiscoveryOnUnsupportedResolution,
  createSampleDiscoveries,
  createSampleDiscoveriesLoadingState,
  canSeeCanvas,
  canSeeEmailButton,
  canSeeShareButton,
  updateDiscoveryEngagementCounts
}) => {
  const locale = useSetting("locale", "en-GB");

  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(false);

  const onUpdateCounts = (data) => {
    const { discoveryId, externalEngagementCount, externalReportViewCount } =
      data;
    updateDiscoveryEngagementCounts({
      discoveryId,
      externalEngagementCount,
      externalReportViewCount
    });
  };
  const tenantId = httpService.getTenantId();

  useEffect(() => {
    pusherService.subscribe({
      channelName: ChannelNames.ENGAGEMENT({ tenantId }),
      eventName: PusherEventNames.UPDATE_COUNTS,
      callback: onUpdateCounts
    });

    return () => {
      pusherService.unsubscribe({
        channelName: ChannelNames.ENGAGEMENT({ tenantId }),
        eventName: PusherEventNames.UPDATE_COUNTS,
        callback: onUpdateCounts
      });
    };
  }, []);

  const onNewDiscovery = () => {
    setShowModal(true);
  };
  const searchPlaceholderUserText = s(
    "discoveries.dashboard.search.user.placeholder",
    "Search by account, opportunity, description"
  );
  const searchPlaceholderAdminText = s(
    "discoveries.dashboard.search.admin.placeholder",
    "Search by account, opportunity, description, username"
  );
  const discoveryTypeHeaderText = s(
    "discoveries.dashboard.table.column10",
    "Discovery Type"
  );
  const usernameHeaderText = s(
    "discoveries.dashboard.table.column9",
    "Username"
  );
  const masterDataVersionHeaderText = s(
    "discoveries.dashboard.table.column11",
    "Version"
  );
  const emailText = s("discoveries.dashboard.email", "Email");
  const shareText = s("discoveries.dashboard.share", "Share");
  const isDiscoveryTypesEnabled = useFeature("discoveryTypesEnabled", false);
  const isDiscoveryVersionsEnabled = useFeature("discoveryVersion", false);
  const allDiscoveries = s(
    "discoveries.dashboard.allDiscoveries",
    "All Discoveries"
  );
  const crmEnabled = useFeature("crmAccountsIntegration", false);
  const salesforceIntegrationEnabled = useFeature(
    "salesforceIntegration",
    false
  );

  const navigationMenuItems = [
    {
      key: MyDiscoveriesConstants.ALL_DISCOVERIES,
      label: allDiscoveries
    },
    {
      key: Phases.CHALLENGES_SOLVED,
      label: <PhaseName phase={Phases.CHALLENGES_SOLVED} />
    },
    {
      key: Phases.KPIS_IMPACTED,
      label: <PhaseName phase={Phases.KPIS_IMPACTED} />
    },
    {
      key: Phases.VALUE_HYPOTHESIS,
      label: <PhaseName phase={Phases.VALUE_HYPOTHESIS} />
    },
    {
      key: Phases.ROI,
      label: <PhaseName phase={Phases.ROI} />
    }
  ];

  const getDiscoveryLinkPath = (record) => {
    if (record.lastViewedPage.includes("/valuemaps")) {
      if (canSeeCanvas) {
        return `/valuemaps/${record.key}`;
      } else {
        return `/discoveries/${record.key}/select-challenges`;
      }
    }

    return `/discoveries/${record.key}${record.lastViewedPage}`;
  };

  const columns = [
    {
      title: s("discoveries.dashboard.table.column1", "Account Name"),
      dataIndex: "name",
      key: "name",
      sorter: true,
      ellipsis: { showTitle: false },
      render: (text, record) => (
        <Space direction={"horizontal"} size={11}>
          <Link to={getDiscoveryLinkPath(record)}>
            {searchTerm ? (
              <Tooltip title={text} placement={"topLeft"}>
                <Highlighter
                  textToHighlight={text}
                  searchWords={[searchTerm]}
                />
              </Tooltip>
            ) : (
              <Tooltip title={text} placement={"topLeft"}>
                {text}
              </Tooltip>
            )}
          </Link>
        </Space>
      )
    },
    {
      title: s("discoveries.dashboard.table.column2", "Opportunity"),
      dataIndex: "opportunity",
      key: "opportunity",
      sorter: true,
      ellipsis: { showTitle: false },
      render: (text, record) => (
        <Link to={getDiscoveryLinkPath(record)}>
          {searchTerm ? (
            <Tooltip title={text} placement={"topLeft"}>
              <Highlighter textToHighlight={text} searchWords={[searchTerm]} />
            </Tooltip>
          ) : (
            <Tooltip title={text} placement={"topLeft"}>
              {text}
            </Tooltip>
          )}
        </Link>
      )
    },
    {
      title: s("discoveries.dashboard.table.column3", "Discovery Description"),
      dataIndex: "version",
      key: "version",
      sorter: true,
      ellipsis: { showTitle: false },
      render: (text, record) => (
        <Link to={getDiscoveryLinkPath(record)}>
          {searchTerm ? (
            <Tooltip title={text} placement={"topLeft"}>
              <Highlighter textToHighlight={text} searchWords={[searchTerm]} />
            </Tooltip>
          ) : (
            <Tooltip title={text} placement={"topLeft"}>
              {text}
            </Tooltip>
          )}
        </Link>
      )
    },
    {
      title: s("discoveries.dashboard.table.column4", "Phase"),
      dataIndex: "phase",
      key: "phase",
      sorter: true,
      render: (phase) => <PhaseName phase={phase} />
    },
    {
      title: s("discoveries.dashboard.table.column8", "Engagement"),
      dataIndex: "externalEngagementCount",
      key: "externalEngagementCount",
      sorter: true,
      render: (text, record) => (
        <EngagementButton
          engagement={record.externalEngagementCount}
          discoveryId={record.key}
        />
      )
    },
    {
      title: s("discoveries.dashboard.table.column5", "Date Modified"),
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: true
    },
    {
      title: s("discoveries.dashboard.table.column6", "Action"),
      dataIndex: "action",
      key: "action"
    }
  ];

  if (canSeeAllDiscoveries) {
    columns.splice(4, 0, {
      title: usernameHeaderText,
      dataIndex: "username",
      key: "username",
      sorter: true,
      render: (text) => (
        <span data-cy={"discovery-username"}>
          {searchTerm ? (
            <Highlighter textToHighlight={text} searchWords={[searchTerm]} />
          ) : (
            text
          )}
        </span>
      )
    });
  }

  if (isDiscoveryTypesEnabled) {
    columns.splice(3, 0, {
      title: discoveryTypeHeaderText,
      dataIndex: "discoveryType",
      key: "discoveryType",
      sorter: true
    });
  }

  if (isDiscoveryVersionsEnabled) {
    columns.splice(3, 0, {
      title: masterDataVersionHeaderText,
      dataIndex: "masterDataVersion",
      key: "masterDataVersion",
      sorter: true
    });
  }

  if (crmEnabled || salesforceIntegrationEnabled) {
    columns.splice(0, 0, {
      title: "",
      fixed: true,
      dataIndex: "crmMatched",
      key: "crmMatched",
      sorter: false,
      width: "58px",
      render: (text, record) => <AccountMatch crmMatched={record.crmMatched} />
    });
  }

  const [selectedPhase, setSelectedPhase] = useState(
    MyDiscoveriesConstants.ALL_DISCOVERIES
  );
  const [searchTerm, setSearchTerm] = useState();
  const [start, setStart] = useState(0);
  const [sort, setSort] = useState(undefined);
  const [order, setOrder] = useState(undefined);
  const [startGetMyDiscoveries, setStartGetMyDiscoveries] = useState(false);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    createSampleDiscoveries();
  }, []);

  useLoadingState(
    createSampleDiscoveriesLoadingState,
    () => {
      setStartGetMyDiscoveries(true);
    },
    () => {
      setError(true);
    }
  );

  useEffect(() => {
    if (startGetMyDiscoveries) {
      doQuery();
    }
  }, [
    fetchMyDiscoveries,
    searchTerm,
    selectedPhase,
    start,
    sort,
    order,
    discoveriesCount,
    pageSize,
    startGetMyDiscoveries
  ]);

  const doQuery = () => {
    const query = {
      start,
      count: pageSize,
      sort,
      order
    };

    if (selectedPhase !== MyDiscoveriesConstants.ALL_DISCOVERIES) {
      query.phase = encodeURIComponent(selectedPhase);
    }

    if (searchTerm) {
      query.search = searchTerm;
    }

    fetchMyDiscoveries(query);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const mapOrder = (order) => {
      switch (order) {
        case "ascend":
          return "asc";
        case "descend":
          return "desc";
        default:
          return;
      }
    };

    if (pagination.current) {
      setStart((pagination.current - 1) * pagination.pageSize);
    }

    setPageSize(pagination.pageSize);

    if (sorter.order) {
      setSort(sorter.columnKey);
      setOrder(mapOrder(sorter.order));
    } else {
      setSort(undefined);
      setOrder(undefined);
    }
  };

  const onChangeFilter = (e) => {
    setSelectedPhase(e.key);
    setStart(0);
  };

  const onSearch = (e) => {
    setSearchTerm(e);
    setStart(0);
  };

  const data = discoveries.map((discovery) => ({
    key: discovery._id,
    name: discovery.name,
    opportunity: discovery.opportunity,
    currency: discovery.currency,
    version: discovery.version,
    phase: discovery.phase,
    externalEngagementCount: discovery.externalEngagementCount,
    username: discovery.username,
    updatedAt: new Date(discovery.updatedAt).toDateString(),
    lastViewedPage: discovery.lastViewedPage,
    crmMatched: discovery.crmMatched,
    discoveryType: discovery.discoveryType,
    masterDataVersion: discovery.masterDataVersion,
    mandatoryQuestionsCompleted: discovery.mandatoryQuestionsCompleted,
    action: (
      <ActionSpace size="middle">
        {canSeeEmailButton && (
          <EmailButton
            tooltip={emailText}
            discoveryId={discovery._id}
            isMandatoryQuestionsCompleted={
              discovery.mandatoryQuestionsCompleted
            }
            type={"iconPrimary"}
          />
        )}
        {canSeeShareButton && (
          <ShareButton
            tooltip={shareText}
            discoveryId={discovery._id}
            dataCy={"share"}
            type={"iconPrimary"}
          />
        )}
        <MyDiscoveriesDropdown
          discoveryId={discovery._id}
          reportId={discovery.reportId}
          isMandatoryQuestionsCompleted={discovery.mandatoryQuestionsCompleted}
        />
      </ActionSpace>
    )
  }));

  if (error) {
    return <InternalServerError />;
  }

  const currentPagination = pagination
    ? {
        current: Math.floor(pagination.start / pageSize) + 1,
        total: pagination.total,
        pageSize
      }
    : undefined;

  const renderCallToAction = () => (
    <CreateFirstDiscovery onNewDiscovery={onNewDiscovery} />
  );

  return (
    <AlternateViewportContent
      alwaysShowContent={showDiscoveryOnUnsupportedResolution}
      alternate={<DiscoveryUnsupportedResolutionPage />}
    >
      <Page header={<MyDiscoveriesHeader />}>
        <FullPageLayout>
          <Container>
            <Left>
              <TitleContainer>
                <Space size={8} direction={"horizontal"}>
                  <Heading level={"h3"}>
                    {s("discoveries.dashboard.page.header", "My Discoveries")}
                  </Heading>
                  <Text>
                    (
                    {
                      <span data-cy={"discoveries-count"}>
                        {formatNumberWithLocale({
                          value: discoveriesCount,
                          locale
                        })}
                      </span>
                    }
                    )
                  </Text>
                </Space>
                <Space size={16} direction={"horizontal"}>
                  {canExportDiscoveries ? <ExportDiscoveriesDropdown /> : null}
                </Space>
              </TitleContainer>
              <NavigationMenu
                mode={"horizontal"}
                selectedKeys={[selectedPhase]}
                onClick={onChangeFilter}
                items={navigationMenuItems}
              />
              <SearchBar
                placeholder={
                  canSeeAllDiscoveries
                    ? searchPlaceholderAdminText
                    : searchPlaceholderUserText
                }
                onSearch={onSearch}
                data-cy={"search-my-discoveries"}
              />

              <Table
                columns={columns}
                dataSource={data}
                onChange={handleTableChange}
                pagination={currentPagination}
              />

              {onlySamples && renderCallToAction()}
            </Left>
          </Container>
          <DiscoveryOperations />
          <DiscoveryOperationsConfirmation />
          <ExportDiscoveryMonitor
            actionType={actionTypes.DISCOVERY_EXPORT_REQUEST}
          />
          <ExportDiscoveryMonitor
            actionType={actionTypes.EXPORT_DISCOVERIES_REQUEST}
          />
          {showModal ? (
            <NewDiscoveryModal onClose={() => setShowModal(false)} />
          ) : null}
        </FullPageLayout>
      </Page>
    </AlternateViewportContent>
  );
};

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  flex-grow: 1;

  h3 {
    margin-bottom: 0;
  }
`;

const Container = styled.div`
  padding: 40px 32px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;

  & .ant-input-search {
    width: 400px;
  }
`;

const Left = styled.div`
  width: 100%;
`;
const ActionSpace = styled(Space)`
  color: ${themeProp("palette.primary")};
`;

const mapStateToProps = (state) => ({
  discoveries: selectDiscoveries(state),
  pagination: selectDiscoveriesPagination(state),
  discoveriesCount: selectDiscoveriesCount(state),
  onlySamples: selectDiscoveriesOnlySamples(state),
  loadingState: selectFetchDiscoveriesLoadingState(state),
  discoveriesFetchError: selectDiscoveriesFetchError(state),
  canSeeAllDiscoveries: hasPermission(state, Permissions.SEE_ALL_DISCOVERIES),
  canExportDiscoveries: hasPermission(state, Permissions.EXPORT_DISCOVERIES),
  showDiscoveryOnUnsupportedResolution: selectCookie(
    state,
    CookieNames.SHOW_DISCOVERY_ON_UNSUPPORTED_RESOLUTION
  ),
  createSampleDiscoveriesLoadingState: selectRequestState(
    state,
    actionTypes.CREATE_SAMPLE_DISCOVERIES_REQUEST
  ),
  canSeeCanvas: hasPermission(state, Permissions.SEE_CANVAS),
  canSeeEmailButton: hasPermission(state, Permissions.SHARE_EDIT),
  canSeeShareButton: hasPermission(state, Permissions.SEE_SHARE_BUTTON)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchMyDiscoveries,
      createSampleDiscoveries,
      updateDiscoveryEngagementCounts
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MyDiscoveries);

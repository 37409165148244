import React, { useContext, useState } from "react";
import styled from "styled-components";
import {
  useString as s,
  useStringTemplate
} from "../../../components/StringProvider";
import Table from "../../../components/Table";
import { SettingsContext } from "../../../components/SettingsProvider";
import { themeProp } from "../../../theme";
import { formatCurrencyString } from "../../../utils/formatting";
import NewButton from "../../../components/NewButton";
import Card from "../../../components/Card";
import { useMobileMediaQuery } from "../../../components/Responsive";
import {
  selectDiscoveryCashflow,
  selectDiscoveryCurrency,
  selectDiscoveryReportId,
  selectDiscoveryROI
} from "../../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import Icon from "../../../components/Icon";
import { exportDiscovery } from "../../../store/actions/discoveries";
import { IntlMessageFormat } from "intl-messageformat";
import isInIFrame from "../../../utils/is-in-iframe";

const ROITable = ({
  cashflow = {},
  roi,
  currency,
  reportId,
  exportDiscovery
}) => {
  const isMobile = useMobileMediaQuery();
  const { settings } = useContext(SettingsContext);
  const discoverySettings = { ...settings, currency };
  const viewNumbersText = s("report.roi.table.viewNumbers", "View numbers");
  const hideNumbersText = s("report.roi.table.hideNumbers", "Hide numbers");
  const exportText = s("report.roi.table.export.title", "Export Table");
  const exportTooltip = s("report.roi.table.export.tooltip", "Export as CSV");
  const [showNumbers, setShowNumbers] = useState(false);
  const { evaluationPeriod } = roi;
  const yearTemplate = useStringTemplate(
    "report.roi.table.year",
    "Year {year}"
  );

  const year = (year) => {
    return new IntlMessageFormat(yearTemplate, "en-US").format({
      year
    });
  };

  const ExportButton = () =>
    !isInIFrame() ? (
      <ExportContainer>
        <NewButton
          type={"iconPrimary"}
          onClick={onExportCSV}
          tooltip={exportTooltip}
          data-cy={"export-roi-button"}
        >
          <Icon name={"csv"} />
        </NewButton>
      </ExportContainer>
    ) : null;

  const columns = [
    {
      dataIndex: "label",
      key: "label",
      fixed: "left",
      title: " "
    }
  ];
  for (let i = 0; i < evaluationPeriod; i++) {
    columns.push({
      title: year(i + 1),
      dataIndex: i,
      key: i,
      render: (value) =>
        value
          ? formatCurrencyString({
              value: Math.round(value),
              settings: discoverySettings
            })
          : "-",
      align: "right"
    });
  }

  if (!isMobile && !isInIFrame()) {
    columns.push({
      title: ".",
      key: "export",
      width: "30px"
    });
  }

  const data = [
    {
      key: "investment",
      label: s("discovery.roi.summary.table.row1", "Investment"),
      ...cashflow.investmentPerYear
    },
    {
      key: "benefit",
      label: s("discovery.roi.summary.table.row2", "Benefit"),
      ...cashflow.benefitPerYear
    },
    {
      key: "netBenefit",
      label: s("discovery.roi.summary.table.row3", "Net Benefit"),
      ...cashflow.netBenefitPerYear
    },
    {
      key: "cumulativeBenefit",
      label: s("discovery.roi.summary.table.row4", "Cumulative Net Benefit"),
      ...cashflow.cumulativeNetBenefitPerYear
    }
  ];

  const onClick = () => {
    setShowNumbers(!showNumbers);
  };

  const onExportCSV = () => {
    exportDiscovery({
      reportId,
      format: "cashflow-csv"
    });
  };

  return isMobile ? (
    showNumbers ? (
      <TightCard>
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          scroll={{ x: "max-content" }}
        />
        <ButtonContainer>
          <NewButton type={"text"} onClick={onClick}>
            {hideNumbersText}
          </NewButton>
          <NewButton
            type={"text"}
            onClick={onExportCSV}
            data-cy={"export-roi-button"}
          >
            {exportText}
          </NewButton>
        </ButtonContainer>
      </TightCard>
    ) : (
      <ButtonContainer>
        <NewButton type={"text"} onClick={onClick}>
          {viewNumbersText}
        </NewButton>
      </ButtonContainer>
    )
  ) : (
    <Container data-cy={"years-breakdown-section"}>
      <ExportButton />
      <StyledTable
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{ x: "max-content" }}
        removeHeaderStyle={true}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  currency: selectDiscoveryCurrency(state),
  roi: selectDiscoveryROI(state),
  cashflow: selectDiscoveryCashflow(state),
  reportId: selectDiscoveryReportId(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      exportDiscovery
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(ROITable);

const StyledTable = styled(Table)`
  & .ant-table-thead .ant-table-cell {
    padding: 0 16px 4px 0 !important;
    border-bottom: 1px solid ${themeProp("palette.gray")};
  }
`;

const ExportContainer = styled.div`
  top: 18px;
  right: 25px;
  position: absolute;
  z-index: 1;
`;

const TightCard = styled(Card)`
  & div {
    padding: 0;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  text-align: center;

  & > button:nth-child(2) {
    margin-left: 20px;
  }
`;

const Container = styled.div`
  overflow: hidden;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  position: relative;

  .ant-table-thead {
    .ant-table-cell {
      background: ${themeProp("palette.surface")};
      text-transform: uppercase;
      color: ${themeProp("palette.secondary")};
      font-size: ${themeProp("typography.small.fontSize")};
      border-bottom-color: ${themeProp("palette.gray2")};
      padding-top: 0;
    }
  }

  tr td {
    border-bottom-color: ${themeProp("palette.gray2")};
  }

  tr[data-row-key="investment"] {
    td {
      border-bottom-width: 0;
    }
  }
  tr[data-row-key="netBenefit"] {
    td {
      border-bottom-color: ${themeProp("palette.secondary")};
    }
  }
  tr[data-row-key="cumulativeBenefit"] {
    td {
      font-weight: bold !important;
      border-bottom-width: 0;
      padding-bottom: 0;
    }
  }
`;

import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  selectDiscoveryChallenges,
  selectDiscoveryId,
  selectDiscoverySetting
} from "../../store/reducers";
import { selectDiscoveryChallengeGroupMembers } from "../../store/selectors/groups-of-challenges";
import Heading from "../../components/Heading";
import NewButton from "../../components/NewButton";
import Icon from "../../components/Icon";
import { Space } from "antd";
import ChallengeCardAndModals from "../../components/Discovery/ChallengeCard/ChallengeCardAndModals";
import React from "react";
import styled from "styled-components";
import { useString as s } from "../../components/StringProvider";
import { CategoryName } from "../../components/Discovery";
import PropTypes from "prop-types";
import { useMobileMediaQuery } from "../../components/Responsive";
import { updateDiscoverySettingsGroup } from "../../store/actions/discoveries";
import ExpandedOrCollapsed from "../../utils/ExpandedOrCollapsed";
import { isDiscoveryGroupCollapsed } from "../../store/selectors/discovery";
import { isTypicalChallenge } from "../../utils/filters/challenge-filters";
import DiscoverySettings from "../../utils/discoverySettings";
import ShowCardOptions from "../../utils/show-card-options";

const GroupOfChallenges = ({
  discoveryId,
  groupId,
  discoveryChallengeCodeString,
  collapsed,
  updateDiscoverySettingsGroup
}) => {
  const isMobile = useMobileMediaQuery();
  const collapseSectionText = s("group.button.collapse", "Collapse section");
  const expandSectionText = s("group.button.expand", "Expand section");
  const discoveryChallengeCodes = discoveryChallengeCodeString.split(",");

  const onToggleCollapsed = () => {
    updateDiscoverySettingsGroup({
      discoveryId,
      groupId,
      status: !collapsed
        ? ExpandedOrCollapsed.COLLAPSED
        : ExpandedOrCollapsed.EXPANDED
    });
  };

  return (
    <Container data-cy={"group"} isMobile={isMobile}>
      <ChallengeTitle styles={{ marginTop: "11px" }}>
        <Heading level={"h3"} data-cy={"group-title"}>
          <CategoryName categoryCode={groupId} />
        </Heading>
        <NewButton
          type={"iconSecondary"}
          tooltip={collapsed ? expandSectionText : collapseSectionText}
          onClick={onToggleCollapsed}
        >
          {collapsed ? <Icon name="plus" /> : <Icon name="minus" />}
        </NewButton>
      </ChallengeTitle>
      <Space direction={"vertical"} size={12} style={{ width: "100%" }}>
        {collapsed
          ? null
          : discoveryChallengeCodes.map((challengeCode, index) => (
              <ChallengeCardAndModals
                key={challengeCode}
                nextChallengeCode={
                  index < discoveryChallengeCodes.length - 1
                    ? discoveryChallengeCodes[index + 1]
                    : undefined
                }
                challengeCode={challengeCode}
                editable={true}
              />
            ))}
      </Space>
    </Container>
  );
};

const Container = styled.div`
  margin-top: ${(props) => (props.isMobile ? "32px" : "0px")};
`;

const ChallengeTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  img {
    width: 20px;
    height: 20px;
  }
`;

GroupOfChallenges.propTypes = {
  groupId: PropTypes.string.isRequired,
  collapsedByDefault: PropTypes.bool.isRequired
};

const mapStateToProps = (state, props) => {
  const showChallenges = selectDiscoverySetting(
    state,
    DiscoverySettings.SHOW_CHALLENGES,
    ShowCardOptions.ALL_CARDS
  );
  const discoveryChallenges =
    selectDiscoveryChallenges(state)?.filter(isTypicalChallenge);
  const isGroupCollapsed = isDiscoveryGroupCollapsed(
    selectDiscoverySetting(state, "groups"),
    props.groupId
  );
  const collapsed =
    isGroupCollapsed !== undefined
      ? isGroupCollapsed
      : props.collapsedByDefault;

  return {
    discoveryChallengeCodeString: selectDiscoveryChallengeGroupMembers(
      discoveryChallenges,
      props.groupId,
      showChallenges
    ),
    collapsed,
    discoveryId: selectDiscoveryId(state)
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateDiscoverySettingsGroup
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(GroupOfChallenges);

import Form, { FormItem } from "../../../components/Form";
import { useString as s } from "../../../components/StringProvider";
import React, { useState } from "react";
import PropTypes from "prop-types";
import Input, { TextArea } from "../../../components/Input";
import NewButton from "../../../components/NewButton";
import { compose } from "redux";
import { useMobileMediaQuery } from "../../../components/Responsive";
import getStringsFormConfig from "./strings-form-config";
import { Space } from "antd";
import styled from "styled-components";
import Heading from "../../../components/Heading";
import Icon from "../../../components/Icon";
import ButtonGroup from "../../../components/ButtonGroup";
import Text from "../../../components/Text";

const UpdateBrandingStringsForm = ({
  form,
  initialValues,
  onFinish,
  submitButtonText
}) => {
  const isMobile = useMobileMediaQuery();
  const stringsFormConfigGroups = getStringsFormConfig(s);
  const initialGroupCollapsedState = stringsFormConfigGroups.reduce(
    (map, group, idx) => {
      map[group.groupId] = idx !== 0;
      return map;
    },
    {}
  );

  const [groupCollapsedState, setGroupCollapsedState] = useState(
    initialGroupCollapsedState
  );

  const maxWidth = "673px";

  const onToggleCollapsed = (groupId) => {
    setGroupCollapsedState({
      ...groupCollapsedState,
      [groupId]: !groupCollapsedState[groupId]
    });
  };

  const GroupCollapse = ({ groupName, groupId }) => (
    <GroupTitle>
      <Heading level={"h4"} data-cy={"group-title"}>
        {groupName}
      </Heading>
      <NewButton
        type={"iconSecondary"}
        onClick={() => onToggleCollapsed(groupId)}
      >
        {groupCollapsedState[groupId] ? (
          <Icon name="plus" />
        ) : (
          <Icon name="minus" />
        )}
      </NewButton>
    </GroupTitle>
  );

  const label = ({ value }) => {
    return (
      <Label>
        <Text variant={"bMedium"} color={"gray4"}>
          {value.label}
        </Text>
        <Text variant={"small"} color={"gray4"}>
          {value.name}
        </Text>
      </Label>
    );
  };

  return (
    <Form
      form={form}
      initialValues={initialValues}
      layout="vertical"
      onFinish={onFinish}
      name={"updateBrandingStringsForm"}
    >
      {stringsFormConfigGroups.map((group) => {
        return (
          <GroupContainer
            isMobile={isMobile}
            inputWidth={maxWidth}
            key={group.groupId}
          >
            <GroupCollapse
              groupId={group.groupId}
              groupName={group.groupName}
            />
            <Space
              direction={"vertical"}
              className={
                groupCollapsedState[group.groupId] ? "collapsed" : undefined
              }
            >
              {group.values.map((value, index) => (
                <FormItem
                  key={index}
                  name={value.name}
                  label={label({ value })}
                  style={{ maxWidth }}
                  rules={[
                    {
                      required: !group.optional
                    }
                  ]}
                >
                  {value.name.match(/\.body$/) ? <TextArea /> : <Input />}
                </FormItem>
              ))}
            </Space>
          </GroupContainer>
        );
      })}
      <FormItem>
        <ButtonGroup mobile={isMobile}>
          <NewButton
            type={"submit"}
            data-cy={"update-branding-strings-form-save"}
          >
            {submitButtonText}
          </NewButton>
        </ButtonGroup>
      </FormItem>
    </Form>
  );
};

const Label = styled.div`
  display: flex;
  flex-direction: column;
`;

const GroupTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const GroupContainer = styled.div`
  margin-bottom: 30px;
  width: ${({ isMobile, isTablet, inputWidth }) =>
    isTablet ? "420px" : isMobile ? undefined : inputWidth};
  display: flex;
  flex-direction: column;
  align-items: stretch;

  & .collapsed {
    display: none;
  }

  & .ant-form-item-label {
    max-height: unset;
  }

  & textarea {
    min-height: 300px;
  }
`;

UpdateBrandingStringsForm.propTypes = {
  form: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  onFinish: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string.isRequired
};

export default compose(UpdateBrandingStringsForm);

import React from "react";
import styled from "styled-components";
import Text from "./Text";
import TooltipIcon from "./TooltipIcon";
import PropType from "prop-types";
import Heading from "./Heading";
import { themeProp } from "../theme";

const Metric = ({
  label,
  large,
  active,
  printMode,
  children,
  help,
  tooltipColor,
  ...props
}) => (
  <MetricContainer active={active || printMode}>
    {children && (
      <MetricValue large={large} {...props} id={"metric-value"}>
        {children}
      </MetricValue>
    )}

    <MetricLabelContainer>
      <MetricLabel printMode={printMode} id={"metric-label"}>
        {label}
      </MetricLabel>
      <TooltipIcon title={help} color={tooltipColor} />
    </MetricLabelContainer>
  </MetricContainer>
);

Metric.propTypes = {
  label: PropType.string,
  large: PropType.bool,
  active: PropType.bool,
  help: PropType.string,
  tooltipColor: PropType.string,
  printMode: PropType.bool
};

export default Metric;

const MetricValue = styled(Heading).attrs((props) => ({
  level: props.large ? "h2" : "h4"
}))`
  color: inherit;
  margin-bottom: 0px;
  max-height: ${(props) =>
    props.large
      ? themeProp("typography.h2.lineHeight")
      : themeProp("typography.h4.lineHeight")};
  overflow: hidden;

  & > * {
    font-size: ${(props) =>
      props.large
        ? themeProp("typography.h2.fontSize")
        : themeProp("typography.h4.fontSize")};

    & > * {
      font-size: ${(props) =>
        props.large
          ? themeProp("typography.h2.fontSize")
          : themeProp("typography.h4.fontSize")};
    }
  }
`;

const MetricLabelContainer = styled.div`
  line-height: 18px;
`;

const MetricLabel = styled(Text).attrs((props) => ({
  variant: props.printMode ? "body" : "small"
}))`
  color: inherit;
  margin-right: 4px;
`;

const MetricContainer = styled.div`
  color: inherit;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`;

import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { compose } from "redux";
import { connect } from "react-redux";
import Heading from "../../../components/Heading";
import Text from "../../../components/Text";
import { useString as s } from "../../../components/StringProvider";
import Card from "../../../components/Card";
import ROIStatistics from "./ROIStatistics";
import ROITable from "./ROITable";
import ROIChart from "./ROIChart";
import ROIBenefit from "./ROIBenefit";
import { DiscoveryTitleBar } from "../../../components/Discovery";
import { Space } from "antd";
import AdditionalBenefit from "./AdditionalBenefit";
import { useMobileMediaQuery } from "../../../components/Responsive";
import EstimationScenario from "./EstimationScenario";
import {
  selectDiscoveryKPIs,
  selectDiscoveryName
} from "../../../store/reducers";
import PropTypes from "prop-types";
import { additionalBenefitsKPIFilter } from "../../../utils/filters/kpi-filters";

export const ROISummary = ({
  discoveryName,
  discoveryKPIs,
  reportMode,
  header = true
}) => {
  const isMobile = useMobileMediaQuery();
  const ref = useRef();
  const heading = s(
    "discovery.roi.page.header2",
    "Return on Investment for {discoveryName}",
    { discoveryName }
  );

  useEffect(() => {
    if (header && ref.current && !reportMode) {
      const Y_OFFSET = 180;
      const y =
        ref.current.getBoundingClientRect().top + window.pageYOffset - Y_OFFSET;
      window.scrollTo({ top: y });
    }
  }, [header, ref, reportMode]);

  const renderHeader = () =>
    reportMode ? null : (
      <ROISummaryHeader>
        <DiscoveryTitleBar>
          <Heading>{heading}</Heading>
        </DiscoveryTitleBar>
        <Text>
          {s(
            "discovery.roi.page.subheader2",
            "Choose your scenario and the benefit categories that should be included to adjust the evaluation"
          )}
        </Text>
      </ROISummaryHeader>
    );

  const showAdditionalBenefits =
    !!additionalBenefitsKPIFilter(discoveryKPIs).length;

  return isMobile ? (
    <Space direction={"vertical"} size={16}>
      <EstimationScenario reportMode={reportMode} />
      <ROIBenefit reportMode={reportMode} />
      {showAdditionalBenefits && <AdditionalBenefit />}
      <WhiteText>
        <ROIStatistics />
      </WhiteText>
      <ROIChart />
      <ROITable />
    </Space>
  ) : (
    <ROISummaryContainer ref={ref}>
      {renderHeader()}
      <EstimationScenarioContainer>
        <EstimationScenario reportMode={reportMode} />
      </EstimationScenarioContainer>
      <Row>
        <ROIBenefit reportMode={reportMode} />
        {showAdditionalBenefits && <AdditionalBenefit />}
        {!showAdditionalBenefits && <ROIStatistics useMaxWidth={false} />}
      </Row>
      <Row>
        {showAdditionalBenefits && <ROIStatistics />}
        <Card>
          <ROITable />
        </Card>
      </Row>
      <ROIChart />
    </ROISummaryContainer>
  );
};

const WhiteText = styled.div`
  color: white;
`;

const ROISummaryContainer = styled.div``;

const EstimationScenarioContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const ROISummaryHeader = styled.div`
  margin-bottom: 16px;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media print {
    &:nth-of-type(2) {
      page-break-after: always;
    }
  }

  ${Card} {
    margin: 8px;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;

ROISummary.propTypes = {
  reportMode: PropTypes.bool
};

const mapStateToProps = (state) => ({
  discoveryName: selectDiscoveryName(state),
  discoveryKPIs: selectDiscoveryKPIs(state)
});

export default compose(connect(mapStateToProps))(ROISummary);

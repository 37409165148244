import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { selectCookie, selectRequestData } from "../../store/reducers";
import actionTypes from "../../store/actionTypes";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import ChallengeLibraryItem from "./ChallengeLibraryItem";
import { setCookie } from "../../store/actions/cookies";
import { CHALLENGE_LIBRARY_COOKIE_NAME } from "./ChallengeLibrary";
import { useMobileMediaQuery } from "../Responsive";
import Heading from "../Heading";
import { CategoryName } from "../Discovery";
import NewButton from "../NewButton";
import Icon from "../Icon";
import { useString as s } from "../StringProvider";

const ChallengeLibraryGroup = ({
  categoryCode,
  challengeLibrary,
  collapsed,
  onAdd,
  data,
  setCookie,
  searchTerm
}) => {
  const isMobile = useMobileMediaQuery();
  const collapseSectionText = s("group.button.collapse", "Collapse section");
  const expandSectionText = s("group.button.expand", "Expand section");
  const [groupChallenges, setGroupChallenges] = useState([]);

  useEffect(() => {
    const filteredChallenges = (
      (challengeLibrary && challengeLibrary.items) ||
      []
    ).filter((challenge) => challenge.categoryCode === categoryCode);

    setGroupChallenges(filteredChallenges);
  }, [challengeLibrary]);

  const toggleState = () => {
    const newData = { ...data };
    newData[categoryCode] = !!collapsed;

    setCookie({
      name: CHALLENGE_LIBRARY_COOKIE_NAME,
      value: JSON.stringify(newData)
    });
  };

  const renderContents = () => {
    return groupChallenges.map((challenge) => {
      return (
        <ChallengeLibraryItem
          key={challenge.code}
          challenge={challenge}
          onAdd={() => onAdd(challenge.code)}
          searchTerm={searchTerm}
        />
      );
    });
  };

  return (
    <Container data-cy={`challenge-library-group-${categoryCode}`}>
      <GroupHeader>
        <Heading level={isMobile ? "h4" : "h3"} data-cy={"group-title"}>
          <CategoryName categoryCode={categoryCode} />
        </Heading>
        <NewButton
          type={"iconSecondary"}
          onClick={toggleState}
          tooltip={collapsed ? expandSectionText : collapseSectionText}
          tooltipPlacement={"topRight"}
        >
          {collapsed ? <Icon name="plus" /> : <Icon name="minus" />}
        </NewButton>
      </GroupHeader>
      {!collapsed ? renderContents() : null}
    </Container>
  );
};

const Container = styled.div`
  & h3,
  & h4 {
    margin-bottom: 8px;
  }
`;

const GroupHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

ChallengeLibraryGroup.propTypes = {
  categoryCode: PropTypes.string.isRequired,
  onAdd: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => {
  const jsonString = selectCookie(state, CHALLENGE_LIBRARY_COOKIE_NAME) || "{}";
  const data = JSON.parse(jsonString);

  return {
    challengeLibrary: selectRequestData(
      state,
      actionTypes.GET_CHALLENGE_LIBRARY_REQUEST
    ),
    collapsed: data[props.categoryCode] !== true,
    data
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setCookie
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ChallengeLibraryGroup
);
